import * as React from "react"
import ContentHeader from "../components/content-header"
import Footer from "../components/footer"

export default (): JSX.Element => {
	return (
		<div className="container">
			<ContentHeader lang="en" />
			<br />
			<br />
			<h1>Imprint</h1>

			<p>
				Flughafen Stuttgart GmbH (Stuttgart Airport)
				<br />
				represented by the Board of Management
				<br />
				Walter Schoefer (Spokesman)
				<br />
				Ulrich Heppe
				<br />
				<br />
				Flughafenstraße 32
				<br />
				70629 Stuttgart
				<br />
				<br />
				Postfach 23 04 61
				<br />
				70624 Stuttgart
				<br />
				<br />
				phone: +49 711 948-0
				<br />
				fax: +49 711 948-2241
				<br />
				E-Mail:{" "}
				<a href="mailto:info@stuttgart-airport.com">
					info@stuttgart-airport.com
				</a>{" "}
				<br />
				<br />
				VAT identification number: DE 147856205
				<br />
				Registrations: Local court Stuttgart HRB Nr. 969
			</p>

			<h2>Legal notes, Copyright, Trademark law and order property rights</h2>

			<p>
				All rights reserved. <br />
				<br />
				Text, images, graphics, sound files, animations and videos as well as the
				arrangement of the same on these websites are protected by copyright and
				other commercial protective rights. The content of these websites may not be
				copied, disseminated, altered or made accessible to third parties for
				commercial purposes. <br />
				<br />
				Unless otherwise indicated, all marks displayed on these websites are
				protected under trademark law or other laws. This applies, in particular, to
				trademarks, type signets, company logos and emblems of Flughafen Stuttgart
				GmbH. The marks of other companies shown on the websites of Flughafen
				Stuttgart GmbH may also be subject to legal protection. <br />
				<br />
				These websites may not be depicted in outside "Frames". It is forbidden to
				automatically read out the flight connections and flight details / schedule
				or any other information from these websites by means of "Spiders",
				"Crawlers", "Bots" or other techniques ("Screen Scraping"), in particular if
				this serves to further process read-out information in third party data
				processing services (e.g. by providing “Apps” which access to this data).{" "}
				<br />
				<br />
				These websites do not grant a license for the use of intellectual property of
				Flughafen Stuttgart GmbH, of affiliates or subsidiaries of Flughafen
				Stuttgart GmbH, or third parties. <br />
				<br />
				The pages in the section “Presse” are not subject to the aforementioned
				restriction. The text and images offered there are freely available for
				editorial use subject to statutory regulations. When reproducing these
				contents, explicit reference must be made to the ownership and copyrights of
				Flughafen Stuttgart GmbH. The contents cannot be changed. They cannot be used
				on other websites without written permission from Flughafen Stuttgart GmbH.
				The contents of these websites, for instance photographs, may be subject to
				rights of third parties. <br />
				<br />
				<strong>
					No warranties or representations, right to make changes without notice,
					technical note
				</strong>{" "}
				<br />
				<br />
				These websites were programmed with utmost care. Nonetheless, the
				correctness, completeness, exactitude or continous availability of the
				information contained cannot be guaranteed. Flughafen Stuttgart GmbH hereby
				excludes all liability for damage resulting directly or indirectly from use
				of these websites. This shall also apply to all information based on flight
				schedule. <br />
				<br />
				All information on these websites is non-binding. Flughafen Stuttgart GmbH
				reserves the right to change, expand or delete all or parts of these websites
				without notice or to cease publication temporarily or permanently. <br />
				<br />
				Moreover we would like to point out that the display of these websites on
				your computer is subject to the system and software used. <br />
				<br />
				<strong>
					Links to other websites (services and applications of third parties)
				</strong>{" "}
				<br />
				<br />
				Flughafen Stuttgart GmbH websites have direct and indirect links to other
				websites. Via Plugins (in particular) websites of social network (providers)
				can be reached. Flughafen Stuttgart GmbH has no control or influence over the
				content and design of these websites and applications connected to. Flughafen
				Stuttgart GmbH undertakes no liability for these websites and applications.
			</p>

			<Footer suffix="en" />
		</div>
	)
}
